import VueJwtDecode from "vue-jwt-decode"
import {
  axiosbd,
  getData
} from "@/api/axios-bd"

const state = {
  name: localStorage.getItem("name") || null,
  accessToken: localStorage.getItem("accessToken") || null,
  refreshToken: localStorage.getItem("refreshToken") || null,
  accessExp: localStorage.getItem("accessExp") || null,
  rol: localStorage.getItem("rol") || null,
  email: localStorage.getItem("email") || null,
  idUser: localStorage.getItem("idUser") || null,
  read: localStorage.getItem("read") === 'false' ? false : true || null,
  normal: localStorage.getItem("normal") === 'false' ? false : true || null,
  super: localStorage.getItem("super") === 'false' ? false : true || null,
}

const getters = {
  getAccessToken(state) {
    return state.accessToken
  },

  loggedIn(state) {
    return state.accessToken != null
  },
  refresh(state) {
    return state.refreshToken
  },
}

const mutations = {
  updateTokens(state, authData) {
    //actualiza el local store y el state
    //despues de hacer log in en el sistema
    const tokenDecoded = VueJwtDecode.decode(String(authData.access))
    state.accessExp = tokenDecoded.exp * 1000
    state.accessToken = authData.access
    state.refreshToken = authData.refresh
    state.accessToken = authData.access
    state.name = tokenDecoded.name
    localStorage.setItem("name", tokenDecoded.name)
    //state.nombre = tokenDecoded.nombre
    state.rol = tokenDecoded.role
    state.email = tokenDecoded.email
    state.idUser = tokenDecoded.id
    state.read = tokenDecoded.read
    state.normal = tokenDecoded.normal
    state.super = tokenDecoded.super
    localStorage.setItem("refreshToken", authData.refresh)
    localStorage.setItem("accessToken", authData.access)
    localStorage.setItem("accessExp", tokenDecoded.exp * 1000)
    localStorage.setItem("email", tokenDecoded.email)
    localStorage.setItem("idUser", tokenDecoded.id)
    localStorage.setItem("read", tokenDecoded.read)
    localStorage.setItem("normal", tokenDecoded.normal)
    localStorage.setItem("super", tokenDecoded.super)
  },
  destroyTokens(state) {
    //funcionalidad para borrar los datos
    //ya se por error de login o por hacer logout
    state.accessToken = null
    state.refreshToken = null
    state.accessExp = null
    state.email = null
    localStorage.clear()
  },
  updateAccess(state, authData) {
    //actualiza el local store y el state
    //despues de hacer log in en el sistema
    const tokenDecoded = VueJwtDecode.decode(String(authData.access))
    state.accessExp = tokenDecoded.exp * 1000
    state.accessToken = authData.access
    state.name = tokenDecoded.name
    state.read = tokenDecoded.read
    state.normal = tokenDecoded.normal
    state.super = tokenDecoded.super
    localStorage.setItem("name", tokenDecoded.name)
    localStorage.setItem("accessToken", authData.access)
    localStorage.setItem("accessExp", tokenDecoded.exp * 1000)
    localStorage.setItem("read", tokenDecoded.read)
    localStorage.setItem("normal", tokenDecoded.normal)
    localStorage.setItem("super", tokenDecoded.super)
  },
}

const actions = {
  refreshToken(context) {
    return new Promise((resolve, reject) => {
      axiosbd
        .post("/api/auth/refresh", {
          refreshToken: `Bearer ${context.state.refreshToken}`,
        }) // esto envia el refreshToken al backend API
        .then((response) => {
          // if API sends back new access and refresh token update the store
          context.commit("updateAccess", response.data)
          //con esto respondo con el accessToken si todo sale bien
          //cuando refreshToken es llamado desde cualquier parte del codigo
          resolve(response.data)
        })
        .catch((err) => {
          alert(err)
          reject(err) // error generating new access and refresh token because refresh token has expired
        })
    })
  },
  loginUser({
    commit
  }, credentials) {
    //metodo en el cual devolvemos una promesa
    //denotando si nos conectamos o no al server
    //ademas envia datos de error para ser mostrado
    //en la vista Login
    return new Promise((resolve, reject) => {
      axiosbd
        .post("api/token/", {
          username: credentials.username,
          password: credentials.password,
        })
        .then((res) => {
          commit("updateTokens", res.data)
          resolve()
        })
        .catch((err) => {
          commit("destroyTokens")
          reject(err)
        })
    })
  },
  logoutUser({
    commit,
    dispatch
  }, getters) {
    commit("destroyTokens")
    //Router.history.push('/')
  },
  async verifyToken({
    state,
    commit
  }) {
    let res = {
      state: true,
      message: "",
    }

    if (state.accessToken) {
      if (state.accessExp < new Date().getTime()) {
        try {
          const response = await axiosbd.post("api/token/refresh/", {
            "refresh": state.refreshToken
          })
          commit("updateAccess", response.data)
        } catch (err) {
          if (err.response.status == 401) {
            res.state = false
            res.message = `Verify token: Unauthorized user, please login.`
          } else {
            res.state = false
            res.message = `Verify token: ${err.response.data.message}`
          }
        }
      }
    } else {
      res.state = false
      res.message = `Verify token: Can't get access token.`
    }
    return res
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}