<template>
  <v-navigation-drawer v-model="toggleSidebar" color="white" app width="300">
    <v-list-item>
      <v-list-item-title class="text-capitalize" align="center">
        <v-img src="@/assets/Diria.png"> </v-img>
      </v-list-item-title>
    </v-list-item>

    <v-list dense rounded>
      <v-list-item-group color="#0D47A1" v-model="selectedItem">
        <!-- usar template para el for como el styling huide the vue lo dice -->
        <!-- option 1 -->
        <v-list-item to="Home" color="#0D47A1" v-if="$store.state.AuthModule.normal || $store.state.AuthModule.super">
          <v-list-item-icon class="mr-5">
            <v-icon small v-text="Menu.option1.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ Menu.option1.text }}</v-list-item-title>
        </v-list-item>

        <!-- option 2 -->
        <v-list-group no-action color="#0D47A1" v-if="$store.state.AuthModule.normal || $store.state.AuthModule.super">
          <template v-slot:activator>
            <v-list-item-icon class="mr-5">
              <v-icon small v-text="Menu.option2.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ Menu.option2.text }}</v-list-item-title>
          </template>
          <template v-slot:appendIcon>
            <v-icon>mdi-menu-down</v-icon>
          </template>

          <v-list-item
            v-for="item in Menu.option2.subMenu"
            :key="item.id"
            link
            color="#0D47A1"
            :to="item.route"
          >
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <!-- option 3 -->
        <v-list-group no-action v-if="$store.state.AuthModule.normal || $store.state.AuthModule.super">
          <template v-slot:activator>
            <v-list-item-icon class="mr-5">
              <v-icon small v-text="Menu.option3.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ Menu.option3.text }}</v-list-item-title>
          </template>
          <template v-slot:appendIcon>
            <v-icon>mdi-menu-down</v-icon>
          </template>

          <v-list-item
            v-for="item in Menu.option3.subMenu"
            :key="item.id"
            link
            color="#0D47A1"
            :to="item.route"
          >
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <!-- option 4 -->
        <v-list-group no-action v-if="$store.state.AuthModule.normal || $store.state.AuthModule.super || $store.state.AuthModule.read">
          <template v-slot:activator>
            <v-list-item-icon class="mr-5">
              <v-icon v-text="Menu.option4.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ Menu.option4.text }}</v-list-item-title>
          </template>
          <template v-slot:appendIcon>
            <v-icon>mdi-menu-down</v-icon>
          </template>

          <v-list-item
            v-for="item in Menu.option4.subMenu"
            :key="item.id"
            v-if="item.permission"
            link
            color="#0D47A1"
            :to="item.route"
            @click="$store.state[item.action] = true"
          >
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <!-- option  5-->
        <v-list-group no-action v-if="$store.state.AuthModule.normal || $store.state.AuthModule.super">
          <template v-slot:activator>
            <v-list-item-icon class="mr-5">
              <v-icon v-text="Menu.option5.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ Menu.option5.text }}</v-list-item-title>
          </template>
          <template v-slot:appendIcon>
            <v-icon>mdi-menu-down</v-icon>
          </template>

          <v-list-item
            v-for="item in Menu.option5.subMenu"
            :key="item.id"
            link
            color="#0D47A1"
            :to="item.route"
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <!-- option  6-->
        <v-list-group no-action v-if="$store.state.AuthModule.normal || $store.state.AuthModule.super">
          <template v-slot:activator>
            <v-list-item-icon class="mr-5">
              <v-icon v-text="Menu.option6.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ Menu.option6.text }}</v-list-item-title>
          </template>
          <template v-slot:appendIcon>
            <v-icon>mdi-menu-down</v-icon>
          </template>

          <v-list-item
            v-for="item in Menu.option6.subMenu"
            :key="item.id"
            link
            color="#0D47A1"
            :to="item.route"
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list-item-group>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn block rounded color="cyan white--text" @click="logout">
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SideBar",

  data() {
    return {
      rutaActual: "",
      //drawer: this.$store.getters.getActiveSidebar,
      selectedItem: 0,
      Menu: {
        option1: {
          icon: "fas fa-home",
          text: "Home",
          route: "/Home",
        },
        option2: {
          icon: "fas fa-money-check-alt",
          text: "Administracion",
          route: "/transactions",
          subMenu: [
            {
              name: "Generar Asientos",
              icon: "mdi-account-multiple-outline",
              route: { name: "GeneraAsientos" },
            },
          ],
        },
        option3: {
          icon: "fas fa-dolly",
          text: "Reportes - Contabilidad",
          route: "/reportes-conta",
          subMenu: {
            option1: {
              name: "Rechazados Diria",
              icon: "mdi-account-multiple-outline",
              route: { name: "RechazadosDiria" },
            },
            option2: {
              name: "Rechazados Jardin",
              icon: "mdi-account-multiple-outline",
              route: { name: "RechazadosJde" },
            },
            option3: {
              name: "Desc Micros Archivo",
              icon: "mdi-account-multiple-outline",
              route: { name: "DescMicrosArchivo" },
            },
            option4: {
              name: "Revision de Docs Diria",
              icon: "mdi-account-multiple-outline",
              route: { name: "RevisionDiria" },
            },
            option5: {
              name: "Costo Vs Pedidos",
              icon: "mdi-account-multiple-outline",
              route: { name: "ReporteCostoPedidos" },
            },
          },
        },
        option4: {
          icon: "fas fa-exchange-alt",
          text: "Reportes - Ventas",
          route: "/reportes-ventas",
          subMenu: {
            option1: {
              name: "Vta A&B Dia/Acum",
              icon: "mdi-account-multiple-outline",
              route: { name: "ReporteVentasAyBDiria" },
              permission: this.$store.state.AuthModule.normal || this.$store.state.AuthModule.super,
              action: 'reporteVtaAyBDiria'
            },
            option2: {
              name: "Vta x Salonero",
              icon: "mdi-account-multiple-outline",
              route: { name: "ReporteSaloneros" },
              permission: this.$store.state.AuthModule.read || this.$store.state.AuthModule.normal || this.$store.state.AuthModule.super,
              action: 'reporteSaloneros'
            },
            option3: {
              name: "Vta x Salonero Detalle",
              icon: "mdi-account-multiple-outline",
              route: { name: "ReporteSalonerosDetalle" },
              permission: this.$store.state.AuthModule.read || this.$store.state.AuthModule.normal || this.$store.state.AuthModule.super,
              action: 'reporteSalonerosDetalle'
            },
            // option2: {
            //   name: "Vta x Segmentos Opera",
            //   icon: "mdi-account-multiple-outline",
            //   route: { name: "VentasSegmentoReal" },
            // },
            option4: {
              name: "Reporte Propinas",
              icon: "mdi-account-multiple-outline",
              route: { name: "ReportePropinas" },
              action: 'reportePropinas',
              permission: this.$store.state.AuthModule.normal || this.$store.state.AuthModule.super,
            },
            
          },
        },
        option5: {
          icon: "fas fa-flag-checkered",
          text: "Presupuesto",
          route: "/Budget",
          subMenu: {
            option1: {
              name: "Registro",
              icon: "mdi-account-multiple-outline",
              route: { name: "Budget" },
              action: 'Budget'
            },
          },
        },
        option6: {
          icon: "fas fa-cog",
          text: "Configuracion",
          route: "/settup",
          subMenu: {
            option1: {
              name: "Configuracion Diria",
              icon: "mdi-account-multiple-outline",
              route: { name: "ConfiguracionDiria" },
            },
            option2: {
              name: "Configuracion Jde",
              icon: "mdi-account-multiple-outline",
              route: { name: "ConfiguracionJde" },
            },
          },
        },
      },
      method: {},
    };
  },
  methods: {
    logout() {
      this.$store
        .dispatch("AuthModule/logoutUser")
        .then(() => {
          this.$router.push({ name: "Login" });
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
  computed: {
    ...mapState("SideBarModule", ["toggleSidebar"]),
  },
};
</script>

<style scoped>
div >>> .v-list a {
  text-decoration: none;
}
</style>
